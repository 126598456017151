<template xmlns:el-col="http://www.w3.org/1999/html">
  <div class="index">
    <el-container>
      <el-header class="header-parent-class">
        <div class="header-div-class">
          <el-menu :default-active="activeIndex" class="el-header-menu" mode="horizontal">
            <el-menu-item><img :src="require('../assets/img/piclast1.png')" alt=""></el-menu-item>
            <el-menu-item><img :src="require('../assets/img/piclast-r.png')" alt=""></el-menu-item>
            <el-menu-item index="5" style="float: right" @click="jumpNvaMenu('Home')"><i class="el-icon-location"></i>我的工作台
            </el-menu-item>
            <el-menu-item index="4" style="float: right" @click="jumpNvaMenu('Statistics')"><i
                class="el-icon-s-order"></i>数据统计
            </el-menu-item>
            <el-menu-item index="3" style="float: right" @click="jumpNvaMenu('EventCenter')"><i
                class="el-icon-s-management"></i>事件管理
            </el-menu-item>
            <el-menu-item index="2" style="float: right" @click="drawer = true"><i class="el-icon-s-opportunity"></i>事件报警
            </el-menu-item>
            <el-menu-item index="1" style="float: right"><i class="el-icon-s-home"></i>首页</el-menu-item>
          </el-menu>
        </div>
      </el-header>
      <el-main class="main-parent-class">
        <TianMap :dvsShow="!drawer_ltr" :dvs_infos="dvs_infos"></TianMap>
        <div class="danger-position" v-show="dangerMsg" @click="drawer = true">
          <div class="danger">
            <span><i class="el-icon-warning"></i> 警告 发现异常 !</span>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-drawer
        title="我是标题"
        :modal="false"
        :visible.sync="drawer"
        :with-header="false"
        size="26%">
      <div class="drawer-header"></div>
      <div class="drawer-main">
        <el-row>
          <div class="container-select">
            <el-row :gutter="2">
              <div>
                <el-col :span="6">
                  <el-select v-model="factorytype_select" placeholder="报警厂区"
                             @change="selectAlarm">
                    <el-option
                        v-for="item in factorytype_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="5">
                  <el-select v-model="warnningtype_select" placeholder="报警类型"
                             @change="selectAlarm">
                    <el-option
                        v-for="item in warnningtype_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="5">
                  <el-select v-model="leveltype_select" placeholder="报警等级"
                             @change="selectAlarm">
                    <el-option
                        v-for="item in leveltype_options"
                        :key="item.value"
                        :label="item.label+'级'"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="statustype_select" placeholder="状态" @change="selectAlarm">
                    <el-option
                        v-for="item in statustype_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                  <el-button @click="resetSelect" style="width: 100%">重置</el-button>
                </el-col>
              </div>
            </el-row>
          </div>
          <div class="container-warn">
            <el-card shadow="always" v-for="item in warn_data_show" :key="item.pk" class="card-warn">
              <div @click="showCheckAlarm(item)" :class="item.fields.handled == 0 ? 'active':''">
                <el-row :gutter="20">
                  <el-col :span="14">
                    <i class="el-icon-message-solid"></i> <span>{{ item.fields.warnningType }}</span>
                  </el-col>
                  <el-col :span="10">
                    <div style="float: right" v-if="item.fields.handled == 0"><span>待处理</span></div>
                    <div style="float: right" v-else><span>已处理</span></div>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="14">
                    <el-button type="text"><span class="short-warnning">
                      {{ item.fields.address }}，发出{{ item.fields.warnningType }}报警。</span>
                    </el-button>
                  </el-col>
                  <el-col :span="10">
                    <el-button style="float: right;" type="text" class="short-warnning">{{
                        item.timeDescribe
                      }}
                    </el-button>
                  </el-col>
                </el-row>
              </div>
              <div v-show="item.show" class="check-alarm">
                <el-row>
                  <el-divider></el-divider>
                  <el-form ref="form">
                    <el-form-item>
                      <el-input placeholder="解除报警理由.." type="textarea"
                                :rows="4" v-model="textarea"></el-input>
                      <div class="date-picker">
                        <el-date-picker
                            type="datetime"
                            v-model="value_timepicker"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
                        </el-date-picker>
                      </div>
                    </el-form-item>
                    <el-form-item>
                      <el-row :gutter="20">
                        <el-col :span="14">
                          <el-button type="primary" @click="showDrawer_ltr(item)">
                            报警详情
                          </el-button>
                        </el-col>
                        <el-col :span="10">
                          <div style="float: right" v-show="flag_handle">
                            <el-button v-if="item.fields.handled==0"
                                       style="float: right" type="primary"
                                       @click="handledAlarm(item)">解除报警
                            </el-button>
                            <el-button v-else style="float: right" type="info">已解除报警</el-button>
                          </div>
                          <div style="float: right" v-show="!flag_handle">
                            <el-button style="float: right" type="info">暂无权解除报警</el-button>
                          </div>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-form>
                </el-row>
              </div>
            </el-card>
          </div>
        </el-row>
      </div>
    </el-drawer>
    <el-drawer
        title="我是标题"
        :visible.sync="drawer_ltr"
        direction="ltr"
        :modal="false"
        :with-header="false"
        size="26%">
      <div class="drawer-header"></div>
      <div class="alarm-video-details-s">
        <div class="alarm-video-details">
          <el-card>
            <div slot="header" class="media-title">
              <span>报警视频</span>
            </div>
            <div v-html="videohtml"></div>
          </el-card>
        </div>
        <div class="alarm-video-details">
          <el-card>
            <div slot="header" class="media-title">
              <span v-show="!warn_imgUrl==''">报警图片</span>
              <span v-show="warn_imgUrl==''">报警信息</span>
            </div>
            <div class="demo-image__preview" v-show="!warn_imgUrl==''">
              <el-image
                  style="width: 100%;height: 30vh"
                  :src="warn_imgUrl"
                  :preview-src-list="[warn_imgUrl]">
              </el-image>
            </div>
            <div v-show="warn_imgUrl==''">
              <el-row>
                <div style="padding-bottom: 20px;display: inline-block;">
                  <el-input placeholder="氯碱一车间盐库化盐池"></el-input>
                </div>
                <div style="display: inline-block;margin-left: 50px">
                  <el-link type="primary" @click="jumpNvaMenu('YKcenter')">盐库报警事件详情</el-link>
                </div>
              </el-row>
              <el-row>
                <div style="font-size: 16px;margin-top: 35px">
                  <b>上次加盐</b>：{{ warn_data_ltr.prevdatetime }}
                </div>
              </el-row>
              <el-row>
                <div style="font-size: 16px;margin-top: 35px">
                  {{ warn_data_ltr.address }}，在{{
                    warn_data_ltr.datetime
                  }},发出{{ warn_data_ltr.warnningType }}报警，请及时进行处理 。
                </div>
              </el-row>
            </div>
          </el-card>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import axios from '../../api/axios.js'
import {queryAlarmAll, handled, delNewAlarm} from '../../api/requests.js'
import TianMap from "./TianMap.vue";

import Videojs from 'video.js'
import 'video.js/dist/video-js.css'

export default {
  name: "AlarmSystem",
  components: {
    TianMap,
  },
  data() {
    return {
      pageIndex: 1,
      activeIndex: '1',
      drawer: false,
      drawer_ltr: false,
      dangerMsg: false,
      operatorRes: true,
      flag_read: false,
      flag_delete: false,
      flag_handle: false,
      warnningtype_select: '',
      factorytype_select: '',
      leveltype_select: '',
      statustype_select: '',
      textarea: '',
      value_timepicker: '',
      sendMsg_pk: '',
      warn_imgUrl: '',
      warn_videoUrl: '',
      warn_data_ltr: '',
      warn_data: [],
      warn_data_show: [],
      warn_data_show_cache: [],
      warnningtype_options: [],
      factorytype_options: [],
      leveltype_options: [],
      statustype_options: [],
      factoryTypeDefault: {},
      statustypeDefault: {'未处理': '0', '已处理': '1'},
      dvs_infos: [
        {icon: "", title: '今日报警 （未处理 / 全部）', num: 0, num1: 0},
        {icon: "", title: '本月报警 （未处理 / 全部）', num: 0, num1: 0},
        {icon: "", title: '报警点位 （今日 / 本月）', num: 0, num1: 0},
        {icon: "", title: '历史报警 （今年 / 总数)', num: 0, num1: 0},
      ],
      videohtml: ''
    };
  },
  created() {
    this.sendNewAlarm()
  },
  methods: {
    jumpNvaMenu(item) {
      this.$router.push({
        name: item
      })
      this.player.dispose()
    },
    showCheckAlarm(item) {
      item.show = !item.show
      if (item.show === true) {
        this.warn_data_show.forEach(res => {
          if (res.fields !== item.fields) {
            res.show = false
          }
        })
      }
      this.textarea = item.fields.handledDesc
      this.value_timepicker = item.fields.handledTime
    },
    alarming(status) {
      if (status === 'defult') {
        this.dangerMsg = false
      } else {
        this.dangerMsg = true
      }
    },

    handledAlarm(item) {
      let user = localStorage.getItem('Authorization')
      if (user === null || user === '') {
        this.$message.error('错了哦，请在工作台登录账户后进行该操作。');
        return
      }
      let param = {'info': user, 'alarm': JSON.stringify(item), 'desc': this.textarea, 'time': this.value_timepicker}
      handled(param).then(res => {
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
          return
        }
        let nohandle_arr = []
        this.warn_data_show.forEach(res => {
          if (res === item) {
            res.fields.handled = '1' // 已处理
            let date = new Date(res.fields.datetime)
            let date_now = new Date();
            if (date.getFullYear() === date_now.getFullYear() && date.getMonth() === date_now.getMonth() && date.getDate() === date_now.getDate()) {
              this.dvs_infos[0]['num1']--
            }
            if (date.getFullYear() === date_now.getFullYear() && date.getMonth() === date_now.getMonth()) {
              this.dvs_infos[1]['num1']--
            }
          }
          if (res.fields.handled != '1') {
            nohandle_arr.push(res.fields.address)
          }
        })
        this.dvs_infos[2]['num'] = Array.from(new Set(nohandle_arr)).length
        if (res.data.noHandled.length > 0) {
          this.alarming()
        } else {
          this.alarming('defult')
        }
      })
    },
    resetSelect() {
      this.warnningtype_select = '',
          this.factorytype_select = '',
          this.leveltype_select = '',
          this.statustype_select = '',
          this.warn_data_show = this.warn_data
    },

    selectAlarm() {
      let alarm = this.warn_data
      let warn_data_show = []
      alarm.forEach(res => {
        if (this.factorytype_select && this.warnningtype_select && this.leveltype_select && this.statustype_select) {
          if (this.factorytype_select === this.factoryTypeDefault[res.fields.factory] &&
              this.warnningtype_select === res.fields.warnningType &&
              this.leveltype_select === res.fields.level &&
              this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.warnningtype_select && this.leveltype_select) {
          if (this.factorytype_select === this.factoryTypeDefault[res.fields.factory] &&
              this.warnningtype_select === res.fields.warnningType && this.leveltype_select === res.fields.level) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.warnningtype_select && this.statustype_select) {
          if (this.factorytype_select === this.factoryTypeDefault[res.fields.factory] &&
              this.warnningtype_select === res.fields.warnningType && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.leveltype_select && this.statustype_select) {
          if (this.factorytype_select === this.factoryTypeDefault[res.fields.factory] &&
              this.leveltype_select === res.fields.level && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.warnningtype_select && this.leveltype_select && this.statustype_select) {
          if (this.warnningtype_select === res.fields.warnningType &&
              this.leveltype_select === res.fields.level && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.warnningtype_select && this.leveltype_select) {
          if (this.warnningtype_select === res.fields.warnningType &&
              this.leveltype_select === res.fields.level) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.warnningtype_select) {
          if (this.factorytype_select === this.factoryTypeDefault[res.fields.factory] &&
              this.warnningtype_select === res.fields.warnningType) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.leveltype_select) {
          if (this.factorytype_select === this.factoryTypeDefault[res.fields.factory] &&
              this.leveltype_select === res.fields.level) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.statustype_select) {
          if (this.factorytype_select === this.factoryTypeDefault[res.fields.factory] && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.warnningtype_select && this.statustype_select) {
          if (this.warnningtype_select === res.fields.warnningType && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.leveltype_select && this.statustype_select) {
          if (this.leveltype_select === res.fields.level && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select) {
          if (this.factorytype_select === this.factoryTypeDefault[res.fields.factory]) {
            warn_data_show.push(res)
          }
        } else if (this.warnningtype_select) {
          if (this.warnningtype_select === res.fields.warnningType) {
            warn_data_show.push(res)
          }
        } else if (this.leveltype_select) {
          if (this.leveltype_select === res.fields.level) {
            warn_data_show.push(res)
          }
        } else if (this.statustype_select) {
          if (this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        }
      })
      this.warn_data_show = warn_data_show
    },
    showDrawer_ltr(item) {
      this.drawer_ltr = true
      this.warn_imgUrl = item.fields.warnningImgUrl
      this.warn_videoUrl = item.fields.warnningVideoUrl

      let date = new Date(item.fields.datetime);
      item.fields.datetime = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
      let prevdate = date.setHours(date.getHours() - 1);
      item.fields.prevdatetime = this.$moment(prevdate).format('YYYY-MM-DD HH:mm:ss')
      this.warn_data_ltr = item.fields

      if (item.fields.warnningVideoUrl !== '') {
        if (this.player) {
          this.player.reset();
          this.player.src([
            {
              src: this.warn_videoUrl,
              type: "video/mp4"
            }
          ]);
          this.player.load(this.warn_videoUrl);
          // this.player.play()
        }
        this.videohtml = `<video id="videoPlayer" class="video-js" style="width:100%;height:30vh"></video>`;
        this.$nextTick(() => {
          this.player = Videojs("videoPlayer", {
            // autoplay: 'muted',//自动播放
            controls: true,//用户可以与之交互的控件
            loop: true,//视频一结束就重新开始
            muted: false,//默认情况下将使所有音频静音
            // aspectRatio: "16:9",//显示比率
            // poster: this.warn_videoUrl,
            fullscreen: {
              options: {navigationUI: 'hide'}
            },
            // techOrder: ["html5", "flvjs"],// 兼容顺序
            html5: {
              hls: {
                withCredentials: true
              }
            },
            sources: [{src: this.warn_videoUrl, type: "video/mp4"}]
          });
        })
      }
    },
    sendNewAlarm() {
      let that = this
      if (typeof (EventSource) !== "undefined") {
        let source = new EventSource(axios.baseUrl + "sendnewalarm/");
        source.onmessage = function (event) {
          if (event.data === 'None') {
            return
          }
          let newAlarm = JSON.parse(event.data).info

          newAlarm.forEach(alarm => {
            delNewAlarm({'pk': alarm.pk})
            alarm['show'] = false
            let date = new Date(alarm.fields.datetime_ct)
            alarm['timeDescribe'] = that.dateFormat(date)
            alarm.fields['datetime'] = alarm.fields.datetime_ct
            delete alarm.fields.datetime_ct

            let warn_data = []
            let warn_data_show = []
            that.warn_data.forEach(res => {
              if (res.fields === alarm.fields) {
                warn_data.push(res.fields)
              }
            })

            that.warn_data_show.forEach(res => {
              if (res.fields === alarm.fields) {
                warn_data_show.push(res.fields)
              }
            })

            if (warn_data.length === 0) {
              let newAlarm = new Array(alarm);
              that.warn_data = newAlarm.concat(that.warn_data)
            }
            if (warn_data_show.length === 0) {
              let newAlarm = new Array(alarm);
              that.warn_data_show = newAlarm.concat(that.warn_data_show)
              that.alarming()
              let alarming = localStorage.getItem('alarming')
              if (alarming) {
                let alarmingTime = Number.parseInt(new Date(JSON.parse(alarming).fields.datetime).getTime() / 1000)
                let alarmTime = Number.parseInt(new Date(alarm.fields.datetime).getTime() / 1000)
                if (alarmTime > alarmingTime) {
                  localStorage.setItem('alarming', JSON.stringify(alarm))
                  that.createNotify("安全Ai通知~", {
                    body: alarm.fields.address + '，发出' + alarm.fields.warnningType + '报警。',
                    icon: "https://www.ipu-cloud.com/favicon.ico",
                    data: "https://www.ipu-cloud.com/",
                    requireInteraction: true,
                  });
                }
              } else {
                localStorage.setItem('alarming', JSON.stringify(alarm))
                that.createNotify("安全Ai通知~", {
                  body: alarm.fields.address + '，发出' + alarm.fields.warnningType + '报警。',
                  icon: "https://www.ipu-cloud.com/favicon.ico",
                  data: "https://www.ipu-cloud.com/",
                  requireInteraction: true,
                });
              }
            }
            that.dvs_infos[0]['num']++
            that.dvs_infos[0]['num1']++
            that.dvs_infos[1]['num']++
            that.dvs_infos[1]['num1']++
            that.dvs_infos[3]['num']++
            that.dvs_infos[3]['num1']++
            let new_site = []
            that.warn_data.forEach(res => {
              if (res.fields.address === alarm.fields.address) {
                new_site.push(res.fields)
              }
            })
            if (new_site.length > 0) {
              that.dvs_infos[2]['num1']++
              that.dvs_infos[2]['num']++
            }
          })
        };
      } else {
        console.log("Sorry, your browser does not support server-sent events...");
      }
    },
    dateFormat(date) {
      let sendDateTime = Number.parseInt(new Date(date).getTime() / 1000)
      let currentDateTime = Number.parseInt(new Date().getTime() / 1000)
      let secTime = currentDateTime - sendDateTime
      if (secTime < 60) {
        return Number.parseInt(secTime) + '秒前'
      } else if (60 <= secTime && secTime < 60 * 60) {
        return Number.parseInt(secTime / 60) + '分钟前'
      } else if (60 * 60 <= secTime && secTime < 60 * 60 * 24) {
        return Number.parseInt(secTime / 60 / 60) + '小时前'
      } else if (60 * 60 * 24 <= secTime && secTime < 60 * 60 * 24 * 7) {
        return Number.parseInt(secTime / 60 / 60 / 24) + '天前'
      } else {
        return this.$moment(date).format('YYYY年MM月DD日')
      }
    },
    selectFormat(data) {
      let select = []
      Array.from(new Set(data)).forEach(res => {
        let defult = {
          value: '',
          label: ''
        }
        defult['value'] = res
        defult['label'] = res
        select.push(defult)
      })
      return select
    },
    createNotify(title, options) {
      let PERMISSON_GRANTED = "granted";
      // let PERMISSON_DENIED = "denied";
      // let PERMISSON_DEFAULT = "default";

      // 如果用户已经允许，直接显示消息，如果不允许则提示用户授权
      if (Notification.permission === PERMISSON_GRANTED) {
        this.notify(title, options);
      } else {
        Notification.requestPermission(function (res) {
          if (res === PERMISSON_GRANTED) {
            this.notify(title, options);
          }
        });
      }
    },
    notify($title, $options) {
      let notification = new Notification($title, $options);
      console.log(notification);
      notification.onshow = function (event) {
        console.log("show : ", event);
      };
      notification.onclose = function (event) {
        console.log("close : ", event);
      };
      notification.onclick = function (event) {
        console.log("click : ", event);
        // 当点击事件触发，打开指定的url
        window.open(event.target.data)
        notification.close();
      };
    },

  },
  mounted() {
    let user = localStorage.getItem('Authorization')
    queryAlarmAll({'info': user}).then(res => {
      let factoryTypeDefault = {}
      res.data.factory.forEach(res => {
        factoryTypeDefault[res.pk] = res.fields.factoryName
      })
      this.factoryTypeDefault = factoryTypeDefault
      let warnningType = []
      let factoryType = []
      let levelType = []
      let statusType = ['已处理', '未处理']
      res.data.info.forEach(res => {
        res['show'] = false
        let date = new Date(res.fields.datetime);
        res['timeDescribe'] = this.dateFormat(date)
        warnningType.push(res.fields.warnningType)
        levelType.push(res.fields.level)
        if (factoryType.indexOf(res.fields.factory) === -1) {
          factoryType.push(factoryTypeDefault[res.fields.factory])
        }
      })
      if (res.data.noHandled.length > 0) {
        this.alarming()
      }
      console.log('res==========', res)
      this.warn_data = res.data.info
      this.warn_data_show = res.data.info
      this.operatorRes = res.data.operator

      this.warnningtype_options = this.selectFormat(warnningType)
      this.leveltype_options = this.selectFormat(levelType)
      this.factorytype_options = this.selectFormat(factoryType)
      this.statustype_options = this.selectFormat(statusType)

      this.dvs_infos[0]['num'] = res.data.alarm_day
      this.dvs_infos[0]['num1'] = res.data.alarm_day_noHandled
      this.dvs_infos[1]['num'] = res.data.alarm_month
      this.dvs_infos[1]['num1'] = res.data.alarm_month_noHandled
      this.dvs_infos[2]['num'] = res.data.alarm_day_address
      this.dvs_infos[2]['num1'] = res.data.alarm_month_addres
      this.dvs_infos[3]['num'] = res.data.alarm_len
      this.dvs_infos[3]['num1'] = res.data.alarm_len
    })
    this.refreshData = setInterval(() => {
      this.warn_data_show.forEach(res => {
        let date = new Date(res.fields.datetime);
        res['timeDescribe'] = this.dateFormat(date)
      })
    }, 1000);

    this.drawer = this.$route.params.isShow
  },
}
</script>

<style scoped>

.el-divider--vertical {
  height: 4vh;
  width: 0.3vw;
  border-radius: 2px;
  background-color: #409EFF;
}

.index {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.el-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.el-main {
  height: 100%;
  overflow: hidden;
}

.el-footer {
  display: flex;
  height: 4vh;
  width: 100%;
  align-items: center;
}


.main-parent-class {
  padding: 0;
  margin: 0;
  width: 100%;
}

.header-div-class img {
  height: 80%;
}

.footer-div {
  margin-bottom: 0;
  width: 100%;
}

.el-footer-menu {
  width: 27vw;
  text-align: center;
  margin: 0 auto;
  border: 0;
  margin-bottom: 2.5vh;
}


.icp-footer-l {
  float: left;
  margin-top: 2vh;
}

.sidebar {
  height: 83vh;
  width: 20px;
  margin-left: 1.5vw;
  margin-top: 2vh;
  color: white;
}

.box-card {
  background-color: transparent;
  border: 0;
}

.container-warn {
  height: calc(96vh - 60px);
  overflow: auto;
  background-color: transparent;
}

.container-warn .el-card {
  width: 99%;
  margin-top: 10px;
}

.container-select {
  width: 99.5%;
  margin-top: 3px;
}

.container-select .el-card {
  border-radius: 0;
  border: 0;
}

.media-title span {
  font-size: larger;
  font-weight: bolder;
}

::-webkit-scrollbar {
  display: none;
}

.box-card .el-card {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  border: 0;
}

.date-picker {
  margin-top: 10px;
}

.alarm-video-details-s {
  margin-top: 7vh;
}

.alarm-video-details .el-card {
  margin-top: 15px;
  height: 39vh;
}

.short-warnning {
  margin-left: 1vh;
  color: #a8aaaf;
}

.danger {
  border-radius: 5px;
  width: 500px;
  height: 30px;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #30384e;
  cursor: pointer;
  box-shadow: 6px 6px 9px #262c3e,
  -6px -6px 9px #30384e;
  color: rgb(255, 10, 1);
  margin: 20px;
  animation: fadenum 1s infinite;
}

.danger:hover {
  background-color: rgba(255, 10, 1, 0.33);
  transition: 0.5s;
}

.danger span {
  margin-left: auto;
  margin-right: auto;
}

.danger-position {
  font-size: small;
  font-weight: bolder;
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

@keyframes fadenum {
  0% {
    background-color: #2B3246;
  }

  50% {
    background-color: rgba(255, 10, 1, 0.33);
  }
}

.active {
  color: #409EFF;
}

.el-footer-menu .el-menu {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}


:deep() .el-drawer {
  margin-top: 60px;
  height: calc(100vh - 60px);
  /*background-color: #748559;*/
  background-image: linear-gradient(to right, #0e5d4a, #091d1e);
  opacity: 0.9;
}

.dvs-title {
  color: white;
  line-height: 4vh;
  margin-top: 4vh;
}

</style>