<template>
  <div>
    <div id="tianMap" class="tianMap">
      <div class="dvs-drawer" v-show="dvsShow">
        <div class="dvs-details">
          <div class="dvs-title">
            <el-row>
              <el-col :span="2">
                <el-divider direction="vertical"></el-divider>
              </el-col>
              <el-col :span="22">
                <el-row>
                  <span class="dvs-title">设备状态</span>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <div class="dvs-main">
            <el-row>
              <div class="dvs-img">
                <img src='src/logo1.gif' width='80%' title='杉谷科技'/>
              </div>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="dvs-status" @click="jumpNvaMenu('DeviceManagement')">
                  <div class="change-icon">
                    <i class="el-icon-success"></i>
                  </div>
                  {{ ipuNum }} 台 <br> 实时分析点位
                </div>
              </el-col>
              <el-col :span="12">
                <div class="dvs-status" @click="jumpNvaMenu('Statistics')">
                  <div class="change-icon">
                    <i class="el-icon-error"></i>
                  </div>
                  {{off_line_num}} 台<br> 离线分析点位
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="dvs-details">
          <div class="dvs-title">
            <el-row>
              <el-col :span="2">
                <el-divider direction="vertical"></el-divider>
              </el-col>
              <el-col :span="22">
                <el-row><span class="dvs-title">报警点位预览</span></el-row>
              </el-col>
            </el-row>
          </div>
          <div class="dvs-main">
            <el-row>
              <el-card shadow="always" v-for="item in dvs_infos" :key="item.pk"
                       @click.native="jumpNvaMenu('EventCenter')">
                <i class="el-icon-message-solid"></i> <span>{{ item.title }}</span>
                <div style="float: right" v-if="item.title==='今日报警 （未处理 / 全部）' || item.title==='本月报警 （未处理 / 全部）' ">
                  <span style="color: red">{{ item.num1 }}</span> / <span>{{
                    item.num
                  }}</span></div>
                <div style="float: right" v-else><span>{{ item.num }}</span> / <span>{{
                    item.num1
                  }}</span></div>
              </el-card>
            </el-row>
          </div>
        </div>
      </div>
      <div class="footer-div">
        <div class="icp-footer">
          <span>©2022 杉谷科技有限公司 All Rights Reserved.</span>
          <span><a href="https://beian.miit.gov.cn/" target="_blank"
                   class="text-gray-800 text-hover-primary">粤ICP备2021057892号-3</a></span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {queryProjects} from '../../api/requests.js'
import {getDeviceManagement} from "../../api/requests";

export default {
  name: "tianMap",
  props: ['dvsShow', 'dvs_infos'],
  data() {
    return {
      timer: "",
      value: 0,
      valueMin: 0,
      nodesData: [],
      ipuNum: 0,
      off_line_num:0,
      marker: '',
      markerInfoWin: '',
      point_x:'',
      point_y:''
    }
  },
  mounted() {

    this.start()
    let user = JSON.parse(localStorage.getItem('Authorization'))
    let factory_name = user.info[0].factory
    let param = {factory:factory_name}
    queryProjects(param).then(res => {
      res.data.data.forEach((item) => {
        this.point(item);
      });
      this.nodesData = res.data.data
      // this.ipuNum = Array.from(new Set(res.data.data)).length
      this.point_x = res.data.data[0].x
      this.point_y = res.data.data[0].y
    });
    this.initMap();
    window.mouse_time_over = this.mouse_time_over;
    window.mouse_time_out = this.mouse_time_out;
    getDeviceManagement(param).then(res => {
                    console.log('res========', res.data.info.ipu)
                    this.ipuNum = res.data.info.on_line_num;
                    this.off_line_num = res.data.info.off_line_num;
                })
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    jumpNvaMenu(item) {
      this.$router.push({
        name: item
      })
    },
    // 初始化天地图
    initMap() {
      // vue项目需要先声明 T = window.T，不然后面无法获取到。
      let T = window.T;
      this.tianMap = new T.Map('tianMap'); // div的id
      // 传参中心点经纬度，以及放大程度，最小1，最大18
      this.tianMap.centerAndZoom(new T.LngLat(this.point_x, this.point_y), 16);
      let ctrl = new T.Control.MapType();
      //添加控件
      this.tianMap.addControl(ctrl);
      this.tianMap.setMapType(window.TMAP_SATELLITE_MAP);
    },
    point(item) {
      let T = window.T;
      // 创建报警标注对象
      let icon = new T.Icon({
        iconUrl: "src/icon1.gif",
        iconSize: new T.Point(40, 60),
        iconAnchor: new T.Point(10, 25)
      });
      let marker = new T.Marker(new T.LngLat(item.x, item.y), {icon: icon});
      // 向地图上添加标注
      this.tianMap.addOverLay(marker);
      // 弹窗模版
      let content = "<div style=\"width: 350px;opacity: 0.8;height: 225px;background: url(src/win1.gif);background-size: 100%\"" +
          "                 onmouseenter='mouse_time_over()' onmouseleave='mouse_time_out()'>\n" +
          "        <div style=\"width: 90%;margin:0 auto 0px;text-align: center;padding-top: 55px\">\n" +
          "            <div style=\"width:120px; height:100px;display: inline-block; background-color: #3a8ee6\">" +
          "                <img src='" + item.camera_img + "' width=100% height=100% title='杉谷科技'/></div>\n" +
          "            <div style='display: inline-block; margin-left: 5px; color: white;font-size: 8px;line-height: 18px;'>\n" +
          "                <b>AI实时分析区域:</b><div style=\"color: #3a8ee6;\">" + item.address + "</div>\n" +
          "                <b>AI设备安装位置:</b><div style=\"color: #3a8ee6;\">" + item.control_room + "</div>\n" +
          "                <b>AI实时分析内容:</b><div style=\"color: #3a8ee6;\">" + item.analysis_zh.toString() + "</div>\n" +
          "            </div>\n" +
          "        </div>\n" +
          "    </div>"
      // 点位的点击事件，展示弹窗
      this.addClickHandler(content, marker);
    },
    // 点位弹窗
    addClickHandler(content, marker) {
      let that = this
      let T = window.T;
      marker.addEventListener('click', function () {
        let markerInfoWin = new T.InfoWindow(); // 创建信息窗口对象
        // 相对于点偏移的位置
        markerInfoWin.setContent(content);
        marker.openInfoWindow(markerInfoWin); // 开启信息窗口
        clearInterval(that.timer);
        that.start_min()
      });
    },
    // 计数器
    start() {
      this.timer = setInterval(() => {
        this.value++;
      }, 3000); // 注意: 第一个参数为方法名的时候不要加括号;
    },
    // 计数器
    start_min() {
      this.timer = setInterval(() => {
        this.valueMin++;
      }, 120000); // 注意: 第一个参数为方法名的时候不要加括号;
    },
    // mouse事件
    mouse_time_over() {
      clearInterval(this.timer);
    },
    mouse_time_out() {
      this.start()
    },
    // 设置动态效果
    setCenter(item) {
      if (!item) {
        return
      }
      if (item.x === 0 || item.y === 0) {
        return
      }
      let T = window.T;
      this.tianMap.centerAndZoom(new T.LngLat(item.x, item.y));
      this.tianMap.removeOverLay(this.marker)
      this.tianMap.removeOverLay(this.markerInfoWin)
      let content = "<div style=\"width: 350px;opacity: 0.8;height: 225px;background: url(src/win1.gif);background-size: 100%\"" +
          "                  onmouseenter='mouse_time_over()' onmouseleave='mouse_time_out()'>\n" +
          "        <div style=\"width: 90%;margin:0 auto 0px;text-align: center;padding-top: 55px\">\n" +
          "            <div style=\"width:120px; height:100px;display: inline-block; background-color: #3a8ee6\">" +
          "                <img src='" + item.camera_img + "' width=100% height=100% title='杉谷科技' /></div>\n" +
          "            <div style='width:170px;display: inline-block; margin-left: 5px; color: white;font-size: 8px;line-height: 18px;'>\n" +
          "                <b>AI实时分析区域:</b><div style=\"color: #3a8ee6;\">" + item.address + "</div>\n" +
          "                <b>AI设备安装位置:</b><div style=\"color: #3a8ee6;\">" + item.control_room + "监控室</div>\n" +
          "                <b>AI实时分析内容:</b><div style=\"color: #3a8ee6;\">" + item.analysis_zh.toString() + "</div>\n" +
          "            </div>\n" +
          "        </div>\n" +
          "    </div>"
      let icon = new T.Icon({
        iconUrl: "src/icon1.gif",
        iconSize: new T.Point(80, 120),
        iconAnchor: new T.Point(10, 25)
      });
      let marker = new T.Marker(new T.LngLat(item.x, item.y), {icon: icon});
      // // 向地图上添加标注
      this.tianMap.addOverLay(marker);
      let markerInfoWin = new T.InfoWindow(); // 创建信息窗口对象
      this.marker = marker
      this.markerInfoWin = markerInfoWin
      // 相对于点偏移的位置
      markerInfoWin.setContent(content);
      marker.openInfoWindow(markerInfoWin); // 开启信息窗口
      this.addClickHandler(content, marker)
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value > 8) {
          this.value = 0
        }
        this.setCenter(this.nodesData[this.value])
      },
    },
    valueMin: {
      handler() {
        clearInterval(this.timer);
        this.start()
      },
    }
  },

}
</script>

<style scoped>
.tianMap {
  width: 100%;
  height: calc(100vh - 60px);
}

:deep() .tdt-infowindow-content-wrapper {
  background-color: transparent;
  box-shadow: 0 0 0 0;
}

.dvs-drawer {
  width: 21%;
  height: calc(100vh - 60px);
  position: absolute;
  z-index: 999;
  opacity: 0.9;
  font-size: 16px;
  background-image: linear-gradient(to right, #091d1e, #0e5d4a)
}

.footer-div {
  bottom: 10px;
  right: 10px;
  z-index: 999;
  color: white;
  position: absolute;
  font-size: 16px;
  background-image: linear-gradient(to right, #091d1e, #0e5d4a)
}

.footer-div a {
  color: white;
}


.dvs-status {
  text-align: center;
  width: 100%;
  color: white;
  line-height: 3vh;
}

.change-icon {
  font-size: 2vw;
}

.dvs-details {
  margin-top: 5vh;
}

.dvs-details .dvs-main {
  margin-top: 4vh;
}

.dvs-details .el-card {
  width: 99%;
  margin-top: 1vh;
}

.dvs-details .dvs-img {
  text-align: center;
  margin-top: -20vh;
  width: 100%
}

.dvs-title {
  color: white;
  line-height: 2vh;
}

:deep() .tdt-infowindow {
  width: 380px;
}

:deep() .tdt-infowindow-tip {
  background-color: transparent;
}
</style>